import { useSubscription } from '@apollo/client';
import { css, Theme } from '@emotion/react';
import { Skeleton, Typography } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import { Types, useTranslation, LiveListenScoreDocument } from '@scorenco/core';

import { ClubLogo, Score } from '../../components';
import { T_KEYS } from '../../translations';

const style = {
  root: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 100%;
    transition: 0.2s;
    color: inherit;
    text-decoration: none;

    .event-item_container {
      display: flex;
      align-items: center;
      min-height: 3rem;
      flex: 1;
      justify-content: space-around;
    }

    .event-item_footer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.vars.palette.background.default};
      margin: 0 1rem;
      padding: 0 1.5rem;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      opacity: 0.75;

      &.event-item_footer-live {
        position: relative;
        background-color: #cc062a15;

        &::before {
          position: absolute;
          left: 0.5rem;
          content: '';
          height: 0.5rem;
          width: 0.5rem;
          margin-right: 0.25rem;
          background-color: #cc062a;
          border-radius: 9999px;
          display: inline-block;
        }
      }
    }

    .event-item_footer_text {
      font-size: 0.75rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .event-item_logo {
      width: 1.25rem;
      height: 2rem;
    }

    .event_card_give-score {
      text-align: center;
      font-size: 10px;
      font-style: italic;
    }
    .event-item_shootout {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.vars.palette.background.default};
      margin: 0 0.75rem;
      padding: 0 1.5rem;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      opacity: 0.75;
    }

    .event-item_scoreboard {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .event-item_scores {
      display: flex;
      margin: 0.25rem 0;
      font-weight: bold;
    }

    .event-item_date {
      text-align: center;
      font-size: 0.8em;
      line-height: 1.2;
      font-style: italic;

      > *:last-of-type {
        font-size: 1em;
        line-height: 1.2;
        font-style: normal;
        font-weight: 500;
      }
    }

    .event-item_team {
      flex: 1;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 0 ${theme.spacing(2)};
      font-size: 0.8rem;
    }
    .bold {
      font-weight: 700;
    }
    .event-item_team-away {
      text-align: right;
    }
  `,
};

type TeamNameProps = {
  className?: string;
  team?: any;
  clubId?: number;
  showShortName?: boolean;
};

const TeamName = ({
  className,
  team,
  clubId,
  showShortName,
}: TeamNameProps) => {
  return (
    <div
      className={[
        'event-item_team',
        className,
        clubId &&
          (team?.club_id === clubId || team?.is_team_of_club === 1) &&
          'bold',
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {((team?.club_id === clubId || team?.is_team_of_club === 1) &&
      showShortName
        ? team?.name_in_club
        : team?.name_in_competition) || <Skeleton />}
    </div>
  );
};

export type EventItemProps = BoxProps & {
  event?: Types.Competitions_Event_Detail;
  condensed?: boolean;
  clubId?: number;
  showCompetition?: boolean;
  showShortName?: boolean;
  showPlace?: boolean;
  forcedPlace?: string;
  showDate?: boolean;
  scoreSubscription?: boolean;
  isTournament?: boolean;
};

export const EventItem = ({
  event,
  showCompetition = true,
  condensed = false,
  clubId,
  showShortName = false,
  showPlace = true,
  forcedPlace = '',
  showDate = false,
  scoreSubscription = false,
  isTournament = false,
  ...rest
}: EventItemProps) => {
  const { t } = useTranslation();

  const home_score = event?.teams?.[0]?.score;
  const home_shootout_score = event?.teams?.[0]?.data?.shootout;
  const away_score = event?.teams?.[1]?.score;
  const away_shootout_score = event?.teams?.[1]?.data?.shootout;

  const has_shootout = home_shootout_score > 0 || away_shootout_score > 0;

  useSubscription(LiveListenScoreDocument, {
    variables: { eventId: Number(event?.id) },
    skip:
      !event?.id ||
      !scoreSubscription ||
      // Si c'est un tournoi, on ne veut que les mises à jour 2 heures avant et 2 heures après
      (isTournament
        ? new Date(event?.time).getTime() < new Date().getTime() - 7200000 ||
          new Date(event?.time).getTime() > new Date().getTime() + 7200000
        : event?.date.split('T')[0] !== new Date().toISOString().split('T')[0]),
  });

  const isStarted =
    event?.teams?.reduce(
      (acc: boolean, team: any) =>
        acc && team?.score !== null && team?.score !== undefined,
      true
    ) ?? false;

  const isLive = event?.status === 'live';

  let scoreboard;
  if (event?.status === 'to_come') {
    scoreboard = (
      <div className="event-item_date">
        {showDate && event?.date && (
          <div>
            {new Date(event.date).toLocaleDateString('fr-FR', {
              month: 'short',
              day: '2-digit',
            })}
          </div>
        )}
        {event?.time && (
          <div>
            {new Date(event.time).toLocaleTimeString('fr-FR', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>
        )}
      </div>
    );
  } else if (event?.status === 'postponed') {
    scoreboard = (
      <div className="event_card_give-score">{t(T_KEYS.EVENT_POSTPONED)}</div>
    );
  } else if (event?.status === 'withdrawal') {
    scoreboard = (
      <div className="event_card_give-score">{t(T_KEYS.EVENT_WITHDRAWAL)}</div>
    );
  } else if (event?.status === 'live' && !isStarted) {
    scoreboard = (
      <div
        className="event_card_give-score"
        dangerouslySetInnerHTML={{
          __html: t(T_KEYS.EVENT_CARD_GIVE_SCORE) as string,
        }}
      />
    );
  } else {
    scoreboard = (
      <div className="event-item_scoreboard">
        <div className="event-item_scores">
          <Score
            score={home_score}
            opponentScore={away_score}
            isWinner={
              event?.status === 'finish' &&
              (home_score > away_score ||
                (home_score === away_score &&
                  home_shootout_score > away_shootout_score))
            }
            sx={{ width: '1.2rem', height: '2rem', mr: '0.125rem' }}
          />
          <Score
            score={away_score}
            opponentScore={home_score}
            isWinner={
              event?.status === 'finish' &&
              (home_score < away_score ||
                (home_score === away_score &&
                  home_shootout_score < away_shootout_score))
            }
            sx={{ width: '1.2rem', height: '2rem', ml: '0.125rem' }}
          />
        </div>
        {has_shootout && (
          <Typography
            variant="body2"
            fontSize={10}
            className="event-item_shootout"
          >
            TAB : {home_shootout_score} - {away_shootout_score}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <Box css={style.root} {...rest}>
      <div className="event-item_container">
        <ClubLogo
          className="event-item_logo"
          logo={event?.teams?.[0]?.logo}
          name={event?.teams?.[0]?.name_in_competition}
          shieldColor="home"
        />

        {!condensed && (
          <TeamName
            team={event?.teams?.[0]}
            clubId={clubId}
            showShortName={showShortName}
          />
        )}

        {scoreboard}

        {!condensed && (
          <TeamName
            className="event-item_team-away"
            team={event?.teams?.[1]}
            clubId={clubId}
            showShortName={showShortName}
          />
        )}

        <ClubLogo
          className="event-item_logo"
          logo={event?.teams?.[1]?.logo}
          name={event?.teams?.[1]?.name}
          shieldColor="away"
        />
      </div>

      {isTournament ? (
        <div
          className={['event-item_footer', isLive && 'event-item_footer-live']
            .filter(Boolean)
            .join(' ')}
        >
          <div className="event-item_footer_text">
            {[isLive && t(T_KEYS.EVENT_LIVE)].filter(Boolean).join(' - ')}
          </div>
        </div>
      ) : showCompetition && !condensed ? (
        <div
          className={['event-item_footer', isLive && 'event-item_footer-live']
            .filter(Boolean)
            .join(' ')}
        >
          <div className="event-item_footer_text">
            {[
              isLive && !event?.level_name
                ? t(T_KEYS.EVENT_LIVE)
                : event?.level_name,

              showPlace && (forcedPlace || event?.place?.name),
            ]
              .filter(Boolean)
              .join(' - ')}
          </div>
        </div>
      ) : (
        <div
          className={['event-item_footer', isLive && 'event-item_footer-live']
            .filter(Boolean)
            .join(' ')}
        >
          <div className="event-item_footer_text">
            {[isLive && t(T_KEYS.EVENT_LIVE)].filter(Boolean).join(' - ')}
          </div>
        </div>
      )}
    </Box>
  );
};
