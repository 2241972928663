import { useMemo, cloneElement } from 'react';

import { getRouterServiceSingleton } from './RouterService';
import { RouteLinkProps } from './types';
import { useRouterLinkResolver } from './useRouterLinkResolver';

/**
 * Retourne le composant permettant de naviguer
 */
export const RouteLink = ({
  children,
  disabled = false,
  ...rest
}: RouteLinkProps) => {
  const SingletonRouteLink = getRouterServiceSingleton().RouteLink;
  const { routerLinkResolver } = useRouterLinkResolver();

  const linkProps = useMemo(() => {
    return routerLinkResolver(rest);
  }, [JSON.stringify(rest)]);

  if (!linkProps?.href || disabled) {
    return children;
  }

  return (
    <SingletonRouteLink {...rest} {...linkProps}>
      {cloneElement(children, {
        draggable: false,
        style: { userSelect: 'none', WebkitUserDrag: 'none' },
      })}
    </SingletonRouteLink>
  );
};
