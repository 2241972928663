import { PropsWithChildren, useMemo } from 'react';

import { css } from '@emotion/react';
import { Button, Stack, Container, Box } from '@mui/material';
import { useTranslation, RouteLink } from '@scorenco/core';

import { T_KEYS } from '@/translations';

import { Breadcrumbs } from '../Breadcrumbs';

type PageLayoutProps = PropsWithChildren<{
  image?: string;
  breadcrumbs?: Array<
    | {
        label: string;
        href?: {
          routeKey: string;
          params: {
            [key: string]: string | undefined;
          };
        };
        current?: boolean;
      }
    | undefined
  >;
}>;

export const PageLayout = ({
  children,
  image,
  breadcrumbs,
}: PageLayoutProps) => {
  const { t } = useTranslation();

  const authenticated = useMemo(() => {
    if (typeof window !== 'undefined') {
      const cookies = document.cookie;
      return cookies.includes('snc-status=connected');
    }
    return false;
  }, []);

  return (
    <>
      <Stack
        css={(theme) => [
          css`
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5rem;
            z-index: 100;
            padding-top: 1rem;
            background-color: ${theme.vars.palette.background.paper};
            justify-content: center;
            align-items: start;
            box-shadow: 0 0 1rem 1rem ${theme.vars.palette.background.paper};
          `,
        ]}
        direction="row"
        spacing={2}
      >
        {authenticated ? (
          <>
            <RouteLink href={process.env.NEXT_PUBLIC_MOBILE_URL}>
              <Button component="a" color="secondary" variant="outlined">
                Tableau de bord
              </Button>
            </RouteLink>
          </>
        ) : (
          <>
            <RouteLink
              href={[
                `${process.env.NEXT_PUBLIC_MOBILE_URL}/auth/select-sport`,
                typeof window !== 'undefined' &&
                  `redirectTo=${encodeURIComponent(window.location.href)}`,
              ]
                .filter(Boolean)
                .join('?')}
            >
              <Button component="a" color="secondary" variant="contained">
                {t(T_KEYS.AUTHENTIFICATION_SIGN_UP)}
              </Button>
            </RouteLink>
            <RouteLink
              href={[
                `${process.env.NEXT_PUBLIC_MOBILE_URL}/auth/sign-in`,
                typeof window !== 'undefined' &&
                  `redirectTo=${encodeURIComponent(window.location.href)}`,
              ]
                .filter(Boolean)
                .join('?')}
            >
              <Button component="a" color="secondary" variant="outlined">
                {t(T_KEYS.AUTHENTIFICATION_SIGN_IN)}
              </Button>
            </RouteLink>
          </>
        )}
      </Stack>

      <Box
        css={css`
          width: 100%;
          height: 15rem;
          background-size: 100%;
          background-position: center;
          transition: 1s;
          background-image: linear-gradient(
              0deg,
              rgba(0, 39, 74, 0.48),
              rgba(0, 39, 74, 0.48)
            ),
            url(${image});
        `}
      />
      <Container sx={{ position: 'relative', mt: '-10rem', pb: '4rem' }}>
        {breadcrumbs && (
          <Breadcrumbs color="white" sx={{ mb: 2 }} links={breadcrumbs} />
        )}

        {children}
      </Container>
    </>
  );
};
